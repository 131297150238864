import React, { useState } from 'react';
import { IonGrid, IonRow, IonCol, IonCheckbox, IonLabel, IonInput, IonItem, IonButton, IonTextarea, IonModal, IonPopover } from '@ionic/react';
import DayPicker from 'react-day-picker';
import { format } from 'date-fns';
import { updateItem } from '../../apis/logictrials';

import { isValidJson } from '../../utils';

export default function ConfirmArrival({ data, setShowModal, orderObject }) {
    const [selectedDate, setSelectedDate] = useState();
    const [noParcel, setNoParcel] = useState();
    const [isDamaged, setIsDamaged] = useState();
    const [comment, setComment] = useState();

    const [excursion, setExcursion] = useState(false);

    const [showSelectedDate, setShowSelectedDate] = useState({ showPopover: false, event: undefined });

    const submit = () => {

        let tempOrder = orderObject;

        tempOrder.boxTypes = (typeof tempOrder.boxTypes === 'string') && isValidJson(tempOrder.boxTypes) ? JSON.parse(tempOrder.boxTypes) : tempOrder.boxTypes;
        tempOrder.tt4Excursion = excursion ? "yes" : "N/A";
        tempOrder.arrivalDate = {
            tooltip: format(new Date(selectedDate), 'yyyy-MM-dd'),
            label: 'Arrived',
            comment,
            isDamaged,
            noParcel
        };
        updateItem('orders', ["data"], [tempOrder], { id: orderObject.id }).then(res => setShowModal(false));
    };

    return (
        <div>
            <IonGrid>
                <IonRow>
                    <IonCol>
                        <IonItem lines="none">
                            <IonInput onClick={(e) => setShowSelectedDate({ showPopover: true, event: e })} placeholder="Pickup Date" value={selectedDate ? format(new Date(selectedDate), 'MM d y') : ''} />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem>
                            <IonLabel>No Parcel</IonLabel>
                            <IonCheckbox checked={noParcel} onIonChange={e => setNoParcel(e.detail.checked)} />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem>
                            <IonLabel color="danger">
                                Damaged:
                            </IonLabel>
                            <IonCheckbox checked={isDamaged} onIonChange={e => setIsDamaged(e.detail.checked)} />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonTextarea placeholder="Comments" value={comment} onIonChange={e => setComment(e.detail.value)} />
                </IonRow>
                <IonRow>
                    <IonCol style={{ textAlign: 'right', marginTop: '10px' }}>Serial:</IonCol>
                    <IonCol>
                        <IonInput />
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol size-md="12">
                        <IonItem lines="none" style={{ color: 'var(--ion-color-danger)', '--color-hover': 'var(--ion-color-danger)' }}>
                            <IonLabel color="danger">
                                Excursion:
                            </IonLabel>
                            <IonCheckbox color="danger" checked={excursion} value={excursion} onIonChange={e => setExcursion(e.detail.checked)} slot="end" />
                        </IonItem>
                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol>
                        <IonItem>
                            <IonLabel>
                                Remind site  send docs
                            </IonLabel>
                            <IonCheckbox />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>

                    </IonCol>
                    <IonCol>

                    </IonCol>
                    <IonCol style={{ textAlign: 'right' }}>
                        <IonButton color="warning" onClick={submit}>
                            Update
                        </IonButton>
                    </IonCol>
                </IonRow>
            </IonGrid>
            <IonModal
                cssClass="day-modal-picker"
                isOpen={showSelectedDate.showPopover}
                event={showSelectedDate.event}
                onDidDismiss={() => setShowSelectedDate({ showPopover: false, event: undefined })}
            >
                <DayPicker canChangeMonth={true} onDayClick={(day) => { setSelectedDate(day.toString()); setShowSelectedDate({ showPopover: false, event: undefined }); }} />
                <IonButton onClick={() => setShowSelectedDate({ showPopover: false, event: undefined })}>Close</IonButton>

            </IonModal>
        </div>
    )
}
