import React, { useRef, useState, useEffect } from 'react';
import { IonCard, IonText, IonButton, IonCardContent, IonItem, IonInput, IonGrid, IonRow, IonCol, IonAlert } from '@ionic/react';
import jwtDecode from 'jwt-decode';

import Modal from '../../components/Modal';
import ForgotPassword from '../../components/ForgotPassword';
import ChangePassword from '../../components/ChangePassword';

import { ltApiLogin } from '../../apis/logictrials';
import { parseQueryString } from '../../utils';

import './Login.css';

export default function Login({ setChangingPage }) {
    const emailRef = useRef();
    const passwordRef = useRef();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const [showResetPassword, setShowResetPassword] = useState(false);
    const [resetPasswordId, setResetPasswordId] = useState(null);

    function alertDismiss() {
        setChangingPage(false);
        setError('');
    }

    useEffect(() => {
        const urlQueries = parseQueryString();

        if(urlQueries && urlQueries.id && urlQueries.token && !showResetPassword) {
            setShowResetPassword(true);
            setResetPasswordId(urlQueries.id);
        }

        window.addEventListener('keydown', e => {
            if(e && e.key && (e.key === 'Enter')) {
                handleSubmit();
            }
        });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setChangingPage(false);
    }, [setChangingPage]);

    async function handleSubmit(e) {
        if(e) e.preventDefault();
        setError('');
        try {
            ltApiLogin.post('/login', {
                email: emailRef.current.value,
                password: passwordRef.current.value
            }).then(res => {
                if(res.status === 200) {
                    const {accessToken, refreshToken} = res.data;
                    sessionStorage.setItem('demo-current-user', emailRef.current.value);
                    
                    // Session["lt-access-token"] = accessToken;

                    sessionStorage.setItem('lt-access-token', accessToken);
                    sessionStorage.setItem('lt-refresh-token', refreshToken);
                    
                    const decoded = jwtDecode(accessToken);
                    
                    sessionStorage.setItem('lt-user', JSON.stringify(decoded));
                    window.location.href = "/";
                    return;
                }
                setError('failed to sign in');
            });
        } catch {
            setError('failed to sign in');
        }
        setLoading(false);
        
    }

    return (
        <div className="login">
            {!showResetPassword && (
                <IonGrid>
                    <IonRow style={{boxShadow: 'none'}}>
                        <IonCol offset-md="3" size-md="6">
                            <IonCard className="shadow-xl">
                                <IonCardContent>
                                    <div style={{ textAlign: "center", marginBottom: '1.5em' }}>
                                        <img
                                            src="/assets/logic_logo.svg"
                                            alt="logic logo"
                                            style={{ width: "60%", margin: "1em auto" }}
                                        />
                                    </div>
                                    <form onSubmit={handleSubmit} style={{marginTop: "2em" }}>
                                        <IonItem lines="none">
                                            <IonInput type="text" required ref={emailRef} placeholder="email">
                                            </IonInput>
                                        </IonItem>
                                        <IonItem lines="none" style={{marginTop: '1em'}}>
                                            <IonInput type="password" required ref={passwordRef} placeholder="password">
                                            </IonInput>
                                        </IonItem>
                                        <IonButton onClick={() => setChangingPage(true)} type="submit" expand="full" style={{marginTop: '3em'}} disabled={loading}>
                                            Login
                                        </IonButton>
                                    </form>
                                    <div className="forgot-password-div">
                                        <IonText>
                                            <p style={{color: 'var(--ion-color-primary)'}} onClick={() => setShowForgotPassword(true)}>Forgot password</p>
                                        </IonText>
                                    </div>
                                </IonCardContent>                       
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            )}
            {showResetPassword && <ChangePassword id={resetPasswordId} />}
            <IonAlert
                isOpen={error !== ''}
                onDidDismiss={() => alertDismiss()}
                header={'Error'}
                message={error}
                buttons={['OK']}
            />
            <Modal
                showModal={showForgotPassword}
                setShowModal={setShowForgotPassword}
            >
                <ForgotPassword />
            </Modal>
        </div>
    )
}
