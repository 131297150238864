import React, { useState, useEffect, useRef } from "react";
import {
  IonAlert,
  IonFab,
  IonFabButton,
  IonIcon,
  IonGrid,
  IonCol,
  IonRow,
  IonDatetime,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonButton,
  IonToast,
  IonModal,
  IonPopover,
} from "@ionic/react";
import ReactPaginate from "react-paginate";
import BaseTemplate from "../../components/base/BaseTemplate";
import TableList from "../../components/TableList/TableList";
import Search from "../../components/Search/Search";
import Modal from "../../components/Modal";
import { add } from "ionicons/icons";
import Loading from "../../components/Loading";
import OrdersProcessing from "../OrdersProcessing/OrdersProcessing";
import {
  exportArrayToExcel,
  parseQueryString,
  displayAlert,
  isValidDate,
  isValidYear,
  isValidJson,
} from "../../utils";
import BoxLabel from "../BoxLabel";
import {
  getOrderWaybill,
  getDataPerms,
  getData,
  deleteItem,
  generatePdf,
  getOrdersManifesto,
  confirmOrder,
  retryAramexOrders,
} from "../../apis/logictrials";
import ConfirmTt4 from "../../components/actionButtonForms/ConfirmTt4";
import ConfirmArrival from "../../components/actionButtonForms/ConfirmArrival";
import IWRSFollowup from "../../components/actionButtonForms/IWRSFollowup";
import AorTelTimeEmailFollowup from "../../components/actionButtonForms/AorTelTimeEmailFollowup";
import AorDocsFollowup from "../../components/actionButtonForms/AorDocsFollowup";
import FileUploadApi from "../../components/actionButtonForms/FileUploadApi";
import ViewBoxWaybills from "../../components/actionButtonForms/ViewBoxWaybills";
import RetryAramex from "../../components/actionButtonForms/RetryAramex";

import useAsyncEffect from "use-async-effect";
import axios from "axios";
import DayPicker from "react-day-picker";
import { format } from "date-fns";
import decode from "jwt-decode";

import "./OrdersDispatch.css";

const PER_PAGE = 15;

function OrdersDispatch() {
  const [updateReturn, setUpdateReturn] = useState({});
  const [error, setError] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [ordersDispatch, setOrdersDispatch] = useState([]);
  const [unchangedOrdersDispatch, setUnchangedOrdersDispatch] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [arrivalDateChangeIndex, setArrivalDateChangeIndex] = useState(null);
  const [displayOrder, setDisplayOrder] = useState(null);
  const [filterType, setFilterType] = useState("none");
  const [boxLabelSops, setBoxLabelSops] = useState();
  const [showBoxLabelModal, setShowBoxLabelModal] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("Error");
  const today = new Date();
  const lastMonth = new Date();
  lastMonth.setDate(today.getDate() - 30);

  const [selectedStartDate, setSelectedStartDate] = useState(
    lastMonth.toISOString()
  );
  const [prevSelectedStartDate] = useState(lastMonth.toISOString());
  const [showStartDate, setShowStartDate] = useState({
    showPopover: false,
    event: undefined,
  });

  const [selectedEndDate, setSelectedEndDate] = useState(today.toISOString());
  const [showEndDate, setShowEndDate] = useState({
    showPopover: false,
    event: undefined,
  });

  const [showTt4Modal, setShowTt4Modal] = useState(false);
  const [showConfirmArrivalModal, setShowConfirmArrivalModal] = useState(false);
  const [showIwrsModal, setShowIwrsModal] = useState(false);
  const [showAorTelTimeModal, setShowAorTelTimeModal] = useState(false);
  const [showAorDocsModal, setShowAorDocsModal] = useState(false);
  const [confirmTt4Data, setConfirmTt4Data] = useState({});
  const [confirmArrivalData, setConfirmArrivalData] = useState({});
  const [aorTelTimeData, setAorTelTimeData] = useState({});
  const dateTimeRef = useRef();
  const [selectedValueIndex] = useState(null);
  const [showFileUploadModal, setShowFileUploadModal] = useState(false);
  const [updateItem, setUpdateItem] = useState({});
  const [showToast, setShowToast] = useState(false);
  const [toastMessage] = useState("");
  const [boxLabelLink, setBoxLabelLink] = useState();
  const [updateObject, setUpdateObject] = useState();
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);

  // delete
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteOrderNumber, setDeleteOrderNumber] = useState("");

  // confirm
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmId, setConfirmId] = useState(null);

  // box waybills

  const [showBoxWaybills, setShowBoxWaybills] = useState(false);
  const [boxWaybills, setBoxWaybills] = useState([]);

  const accessToken = sessionStorage.getItem("lt-access-token");

  const decoded = accessToken ? decode(accessToken) : false;

  const [showAramexRetryModal, setShowAramexRetryModal] = useState(false);
  const [retryOrderNumber, setRetryOrderNumber] = useState("");

  let headings = [
    "SOPS",
    "orderNumber",
    "requestDate",
    "TT4",
    // "STEM",
    // "TAG",
    "dispatchDate",
    "boxes",
    "waybill",
    "retryAramex",
    "boxWaybills",
    "arrivalDate",
    "IWRS",
    "aorTelTime",
    "aorDocs",
    "sss",
    "confirm",
    "edit",
    "delete",
  ];

  let labels = [
    "SOPS",
    "Order Number",
    "Order Date",
    "TT4",
    // "STEM",
    // "TAG",
    "Dispatch Date",
    "Boxes",
    "Waybill",
    "Retry Aramex",
    "Box Waybills",
    "Arrival Date",
    "IWRS",
    "AOR Tel Time",
    "AOR Docs",
    "SSS",
    "",
    "",
    "",
  ];

  let formattedPermissions = [];

  if (decoded && decoded.perms) {
    const permissions = decoded.perms;

    formattedPermissions = permissions.map((p) => {
      const toIndex = p.lastIndexOf("_") + 1;
      return p.slice(0, toIndex);
    });
  }

  if (!formattedPermissions.includes("orders_d_"))
    headings = headings.filter((h) => h !== "delete");

  if (!formattedPermissions.includes("orders_d_")) labels.pop();

  if (!formattedPermissions.includes("orders_u_"))
    headings = headings.filter((h) => h !== "edit");

  if (!formattedPermissions.includes("orders_u_")) labels.pop();

  if (!formattedPermissions.includes("orders_u_"))
    headings = headings.filter((h) => h !== "confirm");

  if (!formattedPermissions.includes("orders_u_")) labels.pop();

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (filterType === "none") {
      setOrdersDispatch(unchangedOrdersDispatch);
    }
    if (
      selectedStartDate &&
      selectedEndDate &&
      (filterType === "Arrival Date" ||
        filterType === "Request Date" ||
        filterType === "Dispatch Date")
    ) {
      if (new Date(selectedStartDate) > new Date(selectedEndDate)) {
        setSelectedStartDate(prevSelectedStartDate);
        setAlertMessage("start date must be before end date");
        setShowAlert(true);
      }
      if (new Date(selectedStartDate) < new Date(selectedEndDate)) {
        let ed = new Date(selectedEndDate);
        let sd = new Date(selectedStartDate);
        let key = "";
        switch (filterType) {
          case "Request Date": {
            key = "requestDate";
            break;
          }
          case "Dispatch Date": {
            key = "dispatchDate";
            break;
          }
          case "Arrival Date": {
            key = "arrivalDate";
            break;
          }
          default:
            return false;
        }
        const result = unchangedOrdersDispatch.filter((d) => {
          let time = new Date(d[key]);

          if (filterType === "Request Date") {
            time = new Date(d[key]);
          }

          if (filterType === "Dispatch Date") {
            time = new Date(d[key]);
          }

          return sd <= time && time <= ed;
        });

        setLoading(false);
        setOrdersDispatch(result);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStartDate, selectedEndDate, filterType]);

  useAsyncEffect(async () => {
    const urlQueries = parseQueryString();

    if (urlQueries.id) {
      const res = await getData("orders", 0, 9999);
      const data = res.map(({ data, id }) => {
        let tempData = JSON.parse(data);
        tempData.id = id;
        if (!tempData.tt4Excursion) tempData.tt4Excursion = "N/A";
        return tempData;
      });

      const display = data.filter((d) => {
        if (d.id === urlQueries.id) {
          return d;
        }

        return false;
      });

      setOrdersDispatch(display);
      setLoading(false);
    } else {
      if (ordersDispatch.length === 0) {
        fetchData();
      }
    }
  }, []);

  function setSssFileName() {} // ????!!!!

  const fetchData = () => {
    const { roleid } = JSON.parse(sessionStorage.getItem("lt-user"));
    getDataPerms("orders", roleid).then((res) => {
      const data = res.map(({ data, id, order_number }) => {
        let tempData = JSON.parse(data);
        tempData.id = id;
        tempData.orderNumber = order_number;

        if (
          tempData &&
          tempData.boxTypes &&
          typeof tempData.boxTypes === "string" &&
          isValidJson(tempData.boxTypes)
        ) {
          const parsedBoxTypes = JSON.parse(tempData.boxTypes);

          tempData.boxTypes = parsedBoxTypes;

          tempData.boxWaybills = {
            btnColor: "primary",
            btnIcon: "eyeOutline",
            label: "",
            tooltip: "View box waybills",
            waybillData:
              parsedBoxTypes &&
              Array.isArray(parsedBoxTypes) &&
              parsedBoxTypes.map((pbt) => pbt && pbt.aramexReturn),
          };
        } else {
          tempData.boxWaybills = "N/A";
        }

        if (!tempData.tt4Excursion) tempData.tt4Excursion = "N/A";

        if (
          tempData &&
          tempData.dispatchDate &&
          isValidYear(new Date(tempData.dispatchDate))
        ) {
          tempData.dispatchDate = format(
            new Date(tempData.dispatchDate),
            "yyyy-MM-dd"
          );
        }

        if (
          tempData &&
          tempData.arrivalDate &&
          tempData.arrivalDate.tooltip &&
          isValidYear(new Date("20" + tempData.arrivalDate.tooltip))
        ) {
          const arrivalDate = new Date("20" + tempData.arrivalDate.tooltip);

          const arrivalDateMonth = arrivalDate.getMonth();

          let correctedArrivalDate = arrivalDate;

          if (
            tempData &&
            tempData.dispatchDate &&
            isValidDate(new Date(tempData.dispatchDate))
          ) {
            const dispatchDate = new Date(tempData.dispatchDate);

            const dispatchDateMonth = dispatchDate.getMonth();

            if (dispatchDateMonth !== arrivalDateMonth) {
              correctedArrivalDate = arrivalDate.setMonth(arrivalDateMonth + 1);
            }
          }

          tempData.arrivalDate.tooltip = format(
            new Date(correctedArrivalDate),
            "yyyy-MM-dd HH:mm"
          );
        }

        if (
          tempData.aramex &&
          tempData.aramex.received &&
          tempData.aramex.request &&
          tempData.waybill
        ) {
          tempData.waybill.label = tempData.aramex.request;
          tempData.waybill.link = tempData.aramex.received;
        }

        tempData.retryAramex = {
          // "label": "Retry Aramex",
          btnIcon: "refreshOutline",
          tooltip:
            "If no waybill was created, click this to generate a waybill",
          btnColor: "danger",
        };

        return tempData;
      });

      const filteredData = data.filter((d) => !d.isArchived);

      setOrdersDispatch(filteredData);
      setUnchangedOrdersDispatch(filteredData);
      setLoading(false);
    });
  };

  useEffect(() => {
    const filterArr = searchResults.length > 0 ? searchResults : ordersDispatch;
    if (searchQuery) {
      let tempInbounds = filterArr.filter((od) => {
        if (
          od &&
          od.SOPS &&
          od.SOPS.toLowerCase().includes(searchQuery.toLowerCase())
        )
          return true;
        if (
          od &&
          od.orderNumber &&
          od.orderNumber.toLowerCase().includes(searchQuery.toLowerCase())
        )
          return true;
        if (
          od &&
          od.waybill &&
          od.waybill.label &&
          od.waybill.label.toLowerCase().includes(searchQuery.toLowerCase())
        )
          return true;
        if (
          od &&
          od.aramexReturn &&
          od.aramexReturn.request &&
          typeof od.aramexReturn.request === "string" &&
          od.aramexReturn.request
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
        )
          return true;
        try {
          if (
            od &&
            od.boxTypes[0].aramexReturn &&
            od.boxTypes[0].aramexReturn.request &&
            typeof od.boxTypes[0].aramexReturn.request === "string" &&
            od.boxTypes[0].aramexReturn.request
              .toLowerCase()
              .includes(searchQuery.toLowerCase())
          )
            return true;
        } catch (e) {
          return false;
        }
        return false;
      });

      setCurrentPage(0);

      setSearchResults(tempInbounds);
    } else {
      setSearchResults(ordersDispatch);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, ordersDispatch]);

  const revisedOrdersDispatch = searchResults;

  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }

  function handleArrivalDateChange(index) {
    let tempOrders = ordersDispatch;
    tempOrders[index].arrivalDate = "Arrived";
    setOrdersDispatch(tempOrders);
    sessionStorage.setItem("lt-orders-dispatch", JSON.stringify(tempOrders));
    setArrivalDateChangeIndex(null);
  }

  function printManifesto() {
    getOrdersManifesto().then((res) => {
      window.open(res);
    });
  }

  const offset = currentPage * PER_PAGE;

  const currentPageData = revisedOrdersDispatch
    ? revisedOrdersDispatch.slice(offset, offset + PER_PAGE)
    : [];

  const pageCount = revisedOrdersDispatch
    ? Math.ceil(revisedOrdersDispatch.length / PER_PAGE)
    : [];

  const functions = {
    arrivalDate: (_, __, obj) => {
      const data = {
        sponsor: "",
        study: "",
        site: "",
        investigator: "",
        contact: "",
        devices: [""],
      };
      setShowConfirmArrivalModal(true);
      setUpdateReturn(obj);
      setConfirmArrivalData(data);
    },
    IWRS: (_, __, obj) => {
      const evalArr = obj.IWRS.label.split("Done");

      if (evalArr.length < 3) {
        setShowIwrsModal(true);
        setUpdateItem(obj);
      }
    },
    sss: function (obj, __, c) {
      const showLoading = new Event("display-loading");
      const dismissLoading = new Event("dismiss-loading");

      if (obj) setUpdateObject(obj);
      if (c) setUpdateObject(c);

      if (c && c.sss && c.sss.link) {
        window.dispatchEvent(showLoading);
        setTimeout(() => {
          window.dispatchEvent(dismissLoading);
          window.open(c.sss.link);
        }, 3000);
        return;
      }

      if (obj && obj.sss && obj.sss.link) {
        window.dispatchEvent(showLoading);
        setTimeout(() => {
          window.dispatchEvent(dismissLoading);
          window.open(obj.sss.link);
        }, 3000);
        return;
      } else if (obj && obj.orderNumber) {
        window.dispatchEvent(showLoading);
        generatePdf("lineclearpdf", obj.orderNumber).then((res) => {
          setTimeout(() => {
            window.dispatchEvent(dismissLoading);
            window.open(res);
          }, 3000);
        });
      }

      if (!obj) {
        setShowFileUploadModal(true);
      }
    },
    TT4: (_, __, c) => {
      const tt4Data = {
        sponsor: "",
        study: "",
        site: "",
        investigator: "",
        contact: "",
        devices: [""],
      };
      setUpdateItem(c);
      setShowTt4Modal(true);
      setConfirmTt4Data(tt4Data);
    },
    SOPS: () => {},
    waybill: async (waybill, _, orderObject) => {
      if (
        (orderObject && orderObject.logicDriverNeeded) ||
        orderObject.markenDriverNeeded
      ) {
        const showLoading = new Event("display-loading");

        window.dispatchEvent(showLoading);
        const dismissLoading = new Event("dismiss-loading");
        generatePdf(
          "logictrialsdispatchwaybillpdf",
          orderObject.orderNumber,
          orderObject.siteID
        ).then((res) => {
          setTimeout(() => {
            window.dispatchEvent(dismissLoading);
            window.open(res);
          }, 2000);
        });
        return;
      } else if (
        orderObject &&
        orderObject.aramex &&
        orderObject.aramex.received &&
        orderObject.aramex.received !== "null"
      ) {
        window.open(orderObject.aramex.received);
        return;
      }

      if (orderObject && orderObject.orderNumber) {
        const res = await getOrderWaybill(orderObject.orderNumber);

        if (res && typeof res === "string" && res !== "waybill not found") {
          window.open(res);
          return;
        }

        if (res && typeof res === "object" && res.data !== false) {
          window.open(res.data);
        } else {
          axios
            .get(
              `https://nservice.aramex.co.za/Home/GeneratePDFTemplateOne/?waybill_number=${waybill}`
            )
            .then((res) => {
              if (res.data.length > 100) {
                window.open(
                  `https://nservice.aramex.co.za/Home/GeneratePDFTemplateOne/?waybill_number=${waybill}`
                );
              } else {
                displayAlert(
                  "warning",
                  "No waybill found",
                  "No waybill found for this order"
                );
              }
            });
        }
        return;
      }
    },
    retryAramex: (_, __, item) => {
      if (item && item.orderNumber) {
        retryAramexOrders(item.orderNumber).then((res) => {
          fetchData();

          displayAlert(
            "success",
            "Retry Successfull",
            "Or system is generating the new waybill. It will be available in the next 30 seconds."
          );

          setTimeout(() => {
            fetchData();
          }, 10000);
        });
      }
    },
    boxes: async (_, __, obj) => {
      const SOPS = obj.SOPS.split(" ");

      const link = await generatePdf("lineclearpdf", obj.orderNumber);
      setBoxLabelLink(link);
      setBoxLabelSops({
        study: SOPS[0],
        order: SOPS[1],
        cspl: SOPS[2],
        site: SOPS[3],
        boxes: obj.boxes.label,
        boxIndex: 1,
      });
      setShowBoxLabelModal(true);
      if (Number(obj.boxes.label) > 1) {
      }
    },
    aorTelTime: (_, __, c) => {
      setUpdateItem(c);
      setAorTelTimeData({
        sponsor: "",
        study: "",
        site: "",
        investigator: "",
        contact: "",
        devices: [""],
      });
      setShowAorTelTimeModal(true);
    },
    aorDocs: (obj) => {
      setUpdateItem(obj);
      setShowAorDocsModal(true);
    },
    delete: (_, __, obj) => {
      setShowConfirmDelete(true);
      const { id, orderNumber } = obj;

      setDeleteOrderNumber(orderNumber);
      setDeleteId(id);
    },
    edit: (_, __, obj) => {
      setDisplayOrder(obj);
    },
    confirm: (_, __, obj) => {
      if (obj && obj.orderNumber) {
        setShowConfirm(true);
        const { orderNumber } = obj;

        setConfirmId(orderNumber);
      }
    },
    boxWaybills: (_, __, item) => {
      if (
        item &&
        item.boxTypes &&
        Array.isArray(item.boxTypes) &&
        item.boxTypes.length > 0
      ) {
        setBoxWaybills([...item.boxTypes]);
      }

      setShowBoxWaybills(true);
    },
  };

  function excelArray() {
    const temp = [...revisedOrdersDispatch];
    const result = temp.map((r) => {
      let keys = Object.keys(r);
      r = { ...r };
      keys.forEach((k) => {
        if (typeof r[k] === "object") {
          if (k !== "arrivalDate")
            r[k] = r[k] && r[k].label ? r[k].label : r[k];
        }
      });

      if (
        r["boxTypes"] &&
        Array.isArray(r["boxTypes"]) &&
        r["boxTypes"].length > 0
      ) {
        for (let i = 0; i < r["boxTypes"].length; i++) {
          if (r["Temp Range"])
            r["Temp Range"] += ", " + r["boxTypes"][i].boxTemperature;
          if (!r["Temp Range"])
            r["Temp Range"] = r["boxTypes"][i].boxTemperature;

          if (r["Type of Temp Monitor"])
            r["Type of Temp Monitor"] +=
              ", " + r["boxTypes"][i].tempMonitorType;
          if (!r["Type of Temp Monitor"])
            r["Type of Temp Monitor"] = r["boxTypes"][i].tempMonitorType;

          if (r["Box Types"]) r["Box Types"] += ", " + r["boxTypes"][i].name;
          if (!r["Box Types"]) r["Box Types"] = r["boxTypes"][i].name;
        }

        r["Number of Temp Monitors"] = r["boxTypes"].length;
      }

      if (r["boxes"]) r["Number of Boxes"] = r["boxes"];

      if (r["arrivalDate"] && r["arrivalDate"].tooltip) {
        r["Arrival Date"] =
          r["arrivalDate"].tooltip !== "Confirm arrival"
            ? r["arrivalDate"]
            : "N/A";
      } else {
        r["Arrival Date"] = "N/A";
      }

      const ad =
        r["arrivalDate"] && r["arrivalDate"].tooltip
          ? r["arrivalDate"].tooltip
          : "N/A";

      delete r["arrivalDate"];
      delete r["edit"];
      delete r["id"];
      delete r["confirm"];
      delete r["view"];
      delete r["delete"];
      delete r["sss"];
      delete r["wayBill"];
      delete r["cspl"];
      delete r["boxes"];
      delete r["siteId"];
      delete r["siteID"];
      delete r["StudyID"];
      delete r["studyID"];
      delete r["parcels"];
      delete r["STEM"];
      delete r["TAG"];
      delete r["SOPS"];
      delete r["IWRS"];
      // delete r['TT4'];
      delete r["aorDocs"];
      delete r["boxTypes"];
      delete r["aorTelTime"];
      delete r["serviceType"];
      delete r["aramex"];
      delete r["iwrsConfirm"];
      delete r["iwrsComments"];
      delete r["aorDocsComments"];
      delete r["aorDocsReceived"];
      delete r["mawb"];
      delete r["sponsorID"];
      delete r["SponsorID"];
      delete r["tt4Serial"];
      delete r["tt4Received"];
      delete r["tt4PickupDate"];
      delete r["tt4LatestEnrouteDate"];
      delete r["aorTelTimeComments"];
      delete r["aorTelTimeNoDocsReceived"];
      delete r["dispatchData"];
      delete r["aramexNeeded"];
      // delete r['sponsorShortName'];

      if (!r["tt4Excursion"]) r["tt4Excursion"] = "no";
      if (r["tt4Excursion"] === "N/A") r["tt4Excursion"] = "no";
      if (!r["siteNumber"]) r["siteNumber"] = "N/A";
      if (!r["requestDate"]) r["requestDate"] = "N/A";
      if (!r["sponsorShortName"]) r["sponsorShortName"] = "N/A";
      if (!r["studyNumber"]) r["studyNumber"] = "N/A";
      if (!r["packingListNumber"]) r["packingListNumber"] = "N/A";
      if (!r["externalOrderNumber"]) r["externalOrderNumber"] = "N/A";
      if (r["sponsorShortName"]) r["sponsorName"] = r["sponsorShortName"];
      if (!r["sponsorShortName"]) r["sponsorName"] = "N/A";

      delete r["SponsorName"];
      delete r["sponsorShortName"];

      keys = Object.keys(r);

      keys.forEach((k) => {
        const result = k.replace(/([A-Z])/g, " $1").trim();
        const excelKey = result.charAt(0).toUpperCase() + result.slice(1);

        if (!r[k]) r[k] = "N/A";

        r[excelKey] = r[k];

        delete r[k];
      });

      if (r["Tt4 Excursion"] === "N/A") r["Tt4 Excursion"] = "no";

      delete r["T T4"];

      const finalObj = { ...r };

      delete finalObj["Tt4 Excursion"];
      delete finalObj["Retry Status"];
      delete finalObj["Status Code"];

      if (ad && typeof ad === "string" && ad.includes("-0-")) {
        let tempArrival = ad;
        // tempArrival = '20' + r["Arrival  Date"];
        let tempArrivalArr = tempArrival.split("-");

        if (tempArrivalArr && Array.isArray(tempArrivalArr)) {
          tempArrivalArr[1] = tempArrivalArr[1] + "1";

          r["Arrival  Date"] =
            tempArrivalArr[0] +
            "-" +
            tempArrivalArr[1] +
            "-" +
            tempArrivalArr[2];
        }
      }

      if (ad && typeof ad === "string" && ad.includes("20202")) {
        if (ad.length === 18) {
          r["Arrival  Date"] = ad.slice(2, 12);
        } else if (r["Dispatch Date"] && r["Dispatch Date"].includes("202")) {
          r["Arrival  Date"] = r["Dispatch Date"].slice(0, 7);
        }
      } else if (ad && typeof ad === "string" && !ad.includes("Confirm")) {
        r["Arrival  Date"] = ad;
      }

      return {
        "Sponsor Name": r["Sponsor Name"],
        "Study Number": r["Study Number"],
        "Order Number": r["Order Number"],
        Waybill: r["Waybill"],
        "Site Number": r["Site Number"],
        "Request Date": r["Request Date"],
        "Dispatch Date": r["Dispatch Date"],
        "Packing List Number": r["Packing List Number"],
        "External Order Number": r["External Order Number"],

        "Temp Range": r["Temp  Range"],
        "Type of Temp Monitor": r["Type of  Temp  Monitor"],
        "Box Types": r["Box  Types"],
        "Number of Temp Monitors": r["Number of  Temp  Monitors"],
        "Number of Boxes": r["Number of  Boxes"],
        "Arrival Date": r["Arrival  Date"],
        "Tt4 Excursion": r["Tt4 Excursion"],
      };
    });

    return result;
  }

  async function excelArrayWithArchives() {
    const temp = [...revisedOrdersDispatch];

    const res = await getData("archive_orders", 0, 9999);

    const archives = res.map(({ data, id }) => {
      let tempData = JSON.parse(data);
      return tempData;
    });

    let searchFiltersArchives;
    if (searchQuery) {
      searchFiltersArchives = archives.filter((ib) => {
        //search bar function searchs the request
        if (
          ib &&
          ib.id &&
          ib.id.toLowerCase().includes(searchQuery.toLowerCase())
        )
          return true;
        if (
          ib &&
          ib.sponsor &&
          ib.sponsor.toLowerCase().includes(searchQuery.toLowerCase())
        )
          return true;
        if (
          ib &&
          ib.study &&
          ib.study.toLowerCase().includes(searchQuery.toLowerCase())
        )
          return true;
        if (
          ib &&
          ib.clearingAgent &&
          ib.clearingAgent.toLowerCase().includes(searchQuery.toLowerCase())
        )
          return true;
        if (
          ib &&
          ib.courierCoy &&
          ib.courierCoy.toLowerCase().includes(searchQuery.toLowerCase())
        )
          return true;
        if (
          ib &&
          ib.mawb &&
          ib.mawb.toLowerCase().includes(searchQuery.toLowerCase())
        )
          return true;
        if (
          ib &&
          ib.zarValue &&
          ib.zarValue.label &&
          ib.zarValue.label.toLowerCase().includes(searchQuery.toLowerCase())
        )
          return true;
        if (
          ib &&
          ib.receivingDate &&
          ib.receivingDate.label &&
          ib.receivingDate.label
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
        )
          return true;
        if (
          ib &&
          ib.email &&
          ib.email.label &&
          ib.email.label.toLowerCase().includes(searchQuery.toLowerCase())
        )
          return true;
        if (
          ib &&
          ib.confirmArrival &&
          ib.confirmArrival.label &&
          ib.confirmArrival.label
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
        )
          return true;
        if (
          ib &&
          ib.qaCheck &&
          ib.qaCheck.label &&
          ib.qaCheck.label.toLowerCase().includes(searchQuery.toLowerCase())
        )
          return true;
        if (
          ib &&
          ib.inventoryAdded &&
          ib.inventoryAdded.label &&
          ib.inventoryAdded.label
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
        )
          return true;
        if (
          ib &&
          ib.aramexReturn &&
          ib.aramexReturn.request &&
          typeof ib.aramexReturn.request === "string" &&
          ib.aramexReturn.request
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
        )
          return true;
        // if(ib && ib.aramexReturn && ib.aramexReturn.request && ib.aramexReturn.request.toLowerCase().includes(searchQuery.toLowerCase())) return true;
        // console.log(ib.aramexReturn)
        // console.log('Here we')
        // if(ib && ib.aramexRef && ib.aramexRef.label && ib.aramexRef.label.toLowerCase().includes(searchQuery.toLowerCase())) return true;

        return false;
      });
    } else {
      searchFiltersArchives = archives;
    }

    const dateFiltersArchives = filterByDates(searchFiltersArchives);

    let archiveFinal = dateFiltersArchives ? dateFiltersArchives : archives;

    const result = [...temp, ...archiveFinal].map((r) => {
      let keys = Object.keys(r);
      r = { ...r };

      keys.forEach((k) => {
        if (typeof r[k] === "object") {
          r[k] = r[k] && r[k].label ? r[k].label : "N/A";
        }
      });

      delete r["edit"];
      delete r["email"];
      delete r["confirm"];
      delete r["view"];
      delete r["devices"];
      delete r["awaitingstatus"];
      delete r["awaitingqarelease"];

      if (typeof r["confirmArrival"] !== "string" || r["confirmArrival"] === "")
        r["confirmArrival"] = "Unconfirmed";
      if (!r["zarValue"]) r["zarValue"] = "N/A";
      if (!r["packingListNumber"]) r["packingListNumber"] = "N/A";
      if (!r["mawb"]) r["mawb"] = "N/A";
      if (!r["quarantine"]) r["quarantine"] = "N/A";
      if (r["inventoryAdded"]) {
        r["inventoryAdded"] = "Yes";
      } else {
        r["inventoryAdded"] = "No";
      }

      if (!r["boxType"]) r["boxType"] = "N/A";

      if (!r["boxTemperature"]) r["boxTemperature"] = "N/A";

      if (r["qaCheck"] === "") r["qaCheck"] = "Unconfirmed";

      delete r["inventoryAdded"];
      delete r["sss"];
      delete r["index"];
      delete r["delete"];
      delete r["email"];
      delete r["confirmDestruction"];
      delete r["db_id"];
      delete r["studyId"];
      delete r["sponsorId"];
      delete r["qaSteps"];
      delete r["certAnalysis"];
      delete r["sponsorName"];
      delete r["SponsorName"];

      keys = Object.keys(r);

      keys.forEach((k) => {
        const result = k.replace(/([A-Z])/g, " $1");
        const excelKey = result.charAt(0).toUpperCase() + result.slice(1);

        if (!r[k]) r[k] = "N/A";

        r[excelKey] = r[k];

        delete r[k];
      });

      return r;
    });

    return result;
  }
  function clearFilters() {
    setFilterType("none");
  }

  function filterByDates(arr) {
    if (
      selectedStartDate &&
      selectedEndDate &&
      (filterType === "Arrival Date" ||
        filterType === "Request Date" ||
        filterType === "Dispatch Date")
    ) {
      if (new Date(selectedStartDate) > new Date(selectedEndDate)) {
        setSelectedStartDate(prevSelectedStartDate);
        setAlertMessage("start date must be before end date");
        setShowAlert(true);
      }
      if (new Date(selectedStartDate) < new Date(selectedEndDate)) {
        let ed = new Date(selectedEndDate);
        let sd = new Date(selectedStartDate);
        let key = "";
        switch (filterType) {
          case "Request Date": {
            key = "requestDate";
            break;
          }
          case "Dispatch Date": {
            key = "dispatchDate";
            break;
          }
          case "Arrival Date": {
            key = "arrivalDate";
            break;
          }
          default:
            return false;
        }
        if (arr && arr.length > 0) {
          const result = arr.filter((d) => {
            let time = new Date(d[key]);

            if (filterType === "Request Date") {
              time = new Date(d[key]);
            }

            if (filterType === "Dispatch Date") {
              time = new Date(d[key]);
            }

            return sd <= time && time <= ed;
          });

          return result;
        }
      }
    } else {
      return false;
    }
  }

  return (
    <>
      {!displayOrder && (
        <BaseTemplate title="Orders & Dispatch">
          <div>
            <Search setSearchQuery={setSearchQuery} placeholder="Search" />
            <IonGrid>
              <IonRow>
                <IonCol
                  size-xs="12"
                  size-sm="12"
                  size-md={filterType !== "none" ? "2.5" : "2.5"}
                  offset-md="1"
                >
                  <IonItem className="shadow-xl">
                    <IonLabel className="text-gray-600 align-middle text-xl uppercase whitespace-no-wrap font-semibold">
                      Filter Type
                    </IonLabel>
                    <IonSelect
                      interface="popover"
                      value={filterType}
                      onIonChange={(e) => setFilterType(e.detail.value)}
                    >
                      <IonSelectOption value="none">None</IonSelectOption>
                      <IonSelectOption value="Request Date">
                        Request Date
                      </IonSelectOption>
                      <IonSelectOption value="Dispatch Date">
                        Dispatch Date
                      </IonSelectOption>
                    </IonSelect>
                  </IonItem>
                </IonCol>
                {filterType !== "none" && (
                  <>
                    <IonCol size-xs="12" size-sm="12" size-md="2.5">
                      <IonItem
                        className="shadow-xl"
                        onClick={(e) =>
                          setShowStartDate({ showPopover: true, event: e })
                        }
                      >
                        <IonLabel className="text-gray-600 align-middle text-xl uppercase whitespace-no-wrap font-semibold">
                          Start Date
                        </IonLabel>
                        <IonDatetime
                          displayFormat="D MMM YYYY H:mm"
                          min="1997"
                          max="2021"
                          value={selectedStartDate}
                          readonly={true}
                        ></IonDatetime>
                      </IonItem>
                    </IonCol>
                    <IonCol size-xs="12" size-sm="12" size-md="2.5">
                      <IonItem
                        className="shadow-xl"
                        onClick={(e) =>
                          setShowEndDate({ showPopover: true, event: e })
                        }
                      >
                        <IonLabel className="text-gray-600 align-middle text-xl uppercase whitespace-no-wrap font-semibold">
                          End Date
                        </IonLabel>
                        <IonDatetime
                          displayFormat="D MMM YYYY H:mm"
                          min="1997"
                          max="2021"
                          value={selectedEndDate}
                          readonly={true}
                        ></IonDatetime>
                      </IonItem>
                    </IonCol>
                  </>
                )}
                <IonCol
                  size-xs="12"
                  size-sm="12"
                  size-md={filterType !== "none" ? "2.5" : "2.5"}
                >
                  <IonButton
                    expand="full"
                    style={{ height: "50px", marginTop: 0 }}
                    onClick={() =>
                      exportArrayToExcel(
                        excelArray(),
                        `orders-dispatch-${String(
                          new Date().getFullYear()
                        ).replace(
                          "20",
                          ""
                        )}-${new Date().getMonth()}-${new Date().getDate()}`
                      )
                    }
                  >
                    export to excel
                  </IonButton>
                </IonCol>

                <IonCol
                  size-xs="12"
                  size-sm="12"
                  offset={filterType !== "none" ? "1" : "0"}
                  size-md={filterType !== "none" ? "2.5" : "2.5"}
                >
                  <IonButton
                    expand="full"
                    style={{ height: "50px", marginTop: 0 }}
                    onClick={async () => {
                      const exportArray = await excelArrayWithArchives();
                      exportArrayToExcel(
                        exportArray,
                        `orders-dispatch-with-archives-${String(
                          new Date().getFullYear()
                        ).replace(
                          "20",
                          ""
                        )}-${new Date().getMonth()}-${new Date().getDate()}`
                      );
                    }}
                  >
                    export with archives
                  </IonButton>
                </IonCol>
                <IonCol size-xs="12" size-sm="12" size-md={"2.5"}>
                  <IonButton
                    expand="full"
                    style={{ height: "50px", marginTop: 0 }}
                    onClick={printManifesto}
                    color="success"
                  >
                    print manifesto
                  </IonButton>
                </IonCol>
              </IonRow>
              {filterType !== "none" && (
                <IonRow>
                  <IonCol offset-md="1.05">
                    <span
                      style={{
                        cursor: "pointer",
                        color: "var(--ion-color-primary)",
                      }}
                      onClick={() => clearFilters()}
                    >
                      clear filters
                    </span>
                  </IonCol>
                </IonRow>
              )}
            </IonGrid>
          </div>
          <div className="users" style={{ paddingBottom: "5em" }}>
            <h2 className="results-display">
              {revisedOrdersDispatch.length} Result(s)
            </h2>
            <IonGrid>
              <IonRow style={{ boxShadow: "none" }}>
                <IonCol offset-xl="1" size-xl="10">
                  {loading && <Loading loading={loading} />}
                  {!loading &&
                    (currentPageData && currentPageData.length > 0 ? (
                      <TableList
                        items={currentPageData}
                        headings={headings}
                        labels={labels}
                        functions={functions}
                      />
                    ) : (
                      <h1>No search results</h1>
                    ))}
                  <div style={{ margin: "1em 1em" }}>
                    <ReactPaginate
                      previousLabel={"←"}
                      nextLabel={"→"}
                      pageCount={pageCount}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      previousLinkClassName={"pagination__link"}
                      nextLinkClassName={"pagination__link"}
                      disabledClassName={"pagination__link--disabled"}
                      activeClassName={"pagination__link--active"}
                    />
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
            <IonAlert
              isOpen={error !== ""}
              onDidDismiss={() => setError("")}
              header={"Error"}
              message={error}
              buttons={["OK"]}
            />
          </div>
          <IonAlert
            isOpen={showAlert}
            onDidDismiss={() => setShowAlert(false)}
            cssClass="my-custom-class"
            header={"Alert"}
            message={alertMessage}
            buttons={["OK"]}
          />
          {showBoxLabelModal && (
            <Modal
              showModal={showBoxLabelModal}
              setShowModal={setShowBoxLabelModal}
              className="box-label-modal"
            >
              <BoxLabel
                sops={boxLabelSops}
                link={boxLabelLink}
                setShowModal={setShowBoxLabelModal}
              />
            </Modal>
          )}
          <Modal
            showModal={showFileUploadModal}
            setShowModal={setShowFileUploadModal}
          >
            <FileUploadApi
              protocol={"orders-sss"}
              object={updateObject}
              setFileName={setSssFileName}
              selectedIndex={selectedValueIndex}
              setShowModal={setShowFileUploadModal}
              orderNumber={updateObject && updateObject.orderNumber}
            />
          </Modal>
          <IonDatetime
            ref={dateTimeRef}
            displayFormat="MM DD YYYY"
            placeholder="Select Date"
            style={{ visibility: "hidden" }}
            onIonChange={(e) =>
              handleArrivalDateChange(arrivalDateChangeIndex, e.detail.value)
            }
          ></IonDatetime>
          {formattedPermissions &&
            formattedPermissions.includes("orders_c_") && (
              <IonFab
                vertical="bottom"
                horizontal="start"
                slot="fixed"
                style={{
                  marginBottom: window.innerWidth < 900 ? "3em" : "5em",
                  position: "fixed",
                  marginRight: "2em",
                }}
              >
                <IonFabButton routerLink="/create-order">
                  <IonIcon icon={add}></IonIcon>
                </IonFabButton>
              </IonFab>
            )}
          {showTt4Modal && (
            <Modal showModal={showTt4Modal} setShowModal={setShowTt4Modal}>
              <ConfirmTt4
                setShowModal={setShowTt4Modal}
                data={confirmTt4Data}
                updateReturn={updateItem}
              />
            </Modal>
          )}
          <Modal
            showModal={showConfirmArrivalModal}
            setShowModal={setShowConfirmArrivalModal}
          >
            <ConfirmArrival
              data={confirmArrivalData}
              setShowModal={setShowConfirmArrivalModal}
              orderObject={updateReturn}
            />
          </Modal>
          <Modal showModal={showIwrsModal} setShowModal={setShowIwrsModal}>
            <IWRSFollowup
              setShowModal={setShowIwrsModal}
              updateReturn={updateItem}
              showModal={showIwrsModal}
            />
          </Modal>
          <Modal
            showModal={showAorTelTimeModal}
            setShowModal={setShowAorTelTimeModal}
          >
            <AorTelTimeEmailFollowup
              data={aorTelTimeData}
              updateReturn={updateItem}
              setShowModal={setShowAorTelTimeModal}
            />
          </Modal>
          <Modal
            showModal={showAorDocsModal}
            setShowModal={setShowAorDocsModal}
          >
            <AorDocsFollowup
              updateOrder={updateItem}
              fetchData={fetchData}
              setShowModal={setShowAorDocsModal}
            />
          </Modal>
          <Modal showModal={showBoxWaybills} setShowModal={setShowBoxWaybills}>
            <ViewBoxWaybills
              waybills={boxWaybills}
              setShowModal={setShowBoxWaybills}
            />
          </Modal>
          <IonModal
            isOpen={showAramexRetryModal}
            onDidDismiss={() => setShowAramexRetryModal(false)}
          >
            <RetryAramex
              setShowModal={setShowAramexRetryModal}
              orderNumber={retryOrderNumber}
              tableName={"orders"}
              fetchData={fetchData}
            />
          </IonModal>
        </BaseTemplate>
      )}
      {displayOrder && (
        <OrdersProcessing
          orderObject={displayOrder}
          setDisplayOrder={setDisplayOrder}
        />
      )}
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        position="bottom"
        duration={2000}
        color="success"
      />
      <IonAlert
        isOpen={showDeleteAlert}
        onDidDismiss={() => setShowDeleteAlert(false)}
        cssClass="my-custom-class"
        message={"Are you sure you want to delete this order."}
        buttons={["Delete"]}
      />
      <IonAlert
        isOpen={showConfirmDelete}
        onDidDismiss={() => setShowConfirmDelete(false)}
        message={`Are you sure you want to delete<br /> order ${deleteOrderNumber}?`}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            handler: () => setShowConfirmDelete(false),
          },
          {
            text: "Delete",
            cssClass: "danger",
            handler: () => {
              if (deleteId)
                deleteItem("orders", { id: deleteId }).then((_) => fetchData());
            },
          },
        ]}
      />
      <IonAlert
        isOpen={showConfirm}
        onDidDismiss={() => setShowConfirm(false)}
        message={`Are you sure you want to complete<br /> order ${confirmId}?`}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            handler: () => setShowConfirm(false),
          },
          {
            text: "Confirm",
            cssClass: "",
            handler: () => {
              if (confirmId) confirmOrder(confirmId).then(() => fetchData());
            },
          },
        ]}
      />
      <IonPopover
        cssClass="day-modal-picker"
        isOpen={showStartDate.showPopover}
        event={showStartDate.event}
        onDidDismiss={() =>
          setShowStartDate({ showPopover: false, event: undefined })
        }
      >
        <DayPicker
          canChangeMonth={true}
          onDayClick={(day) => {
            setSelectedStartDate(day.toString());
            setShowStartDate({ showPopover: false, event: undefined });
          }}
        />
        <IonButton
          onClick={() =>
            setShowStartDate({ showPopover: false, event: undefined })
          }
        >
          Close
        </IonButton>
      </IonPopover>
      <IonPopover
        cssClass="day-modal-picker"
        isOpen={showEndDate.showPopover}
        event={showEndDate.event}
        onDidDismiss={() =>
          setShowEndDate({ showPopover: false, event: undefined })
        }
      >
        <DayPicker
          canChangeMonth={true}
          onDayClick={(day) => {
            setSelectedEndDate(day.toString());
            setShowEndDate({ showPopover: false, event: undefined });
          }}
        />
        <IonButton
          onClick={() =>
            setShowEndDate({ showPopover: false, event: undefined })
          }
        >
          Close
        </IonButton>
      </IonPopover>
    </>
  );
}

export default OrdersDispatch;
