import React, { useState, useEffect, useRef } from 'react';
import { IonGrid, IonRow, IonCol, IonInput, IonItem, IonButton, IonIcon, IonCheckbox } from '@ionic/react';
import { checkmarkOutline, refreshOutline } from 'ionicons/icons';
import { addUser, getData, updateItem } from '../apis/logictrials';

import ResetPassword from './ResetPassword';

import Select from "react-dropdown-select";

export default function NewUser({editUser, fetchData, closeModal}) {
    const [sponsor, setSponsor] = useState('');
    const [sponsors, setSponsors] = useState([]);
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [sponsorRoleNeeded, setSponsorRoleNeeded] = useState(false);
    const [dashboardAccess, setDashboardAccess] = useState(false);
    const [inboundAccess, setInboundAccess] = useState(false);
    const [orderAccess, setOrderAccess] = useState(false);
    const [returnAccess, setReturnAccess] = useState(false);
    const [destructionAccess] = useState(false);
    const [sponsorAccess] = useState(false);
    const [siteAccess, setSiteAccess] = useState(false);
    const [studyAccess, setStudyAccess] = useState(false);

    const [showResetPassword, setShowResetPassword] = useState(false);

    const selectSponsor = useRef();

    useEffect(() => {
        getData('sponsor', 0, 9999).then(res => {
            setSponsors(res);
            if(selectSponsor && selectSponsor.current && !editUser) selectSponsor.current.clearAll();
        });

        if(editUser) {
            if(editUser.email) setEmail(editUser.email);
            if(editUser.firstname) setFirstName(editUser.firstname);
            if(editUser.lastname) setLastName(editUser.lastname);
            if(editUser.phone) setPhoneNumber(editUser.phone);
        }
        
    }, [editUser]);

    function add() {
        let viewTables = [];

        if(inboundAccess) viewTables.push("inbound");
        if(orderAccess) viewTables.push("orders");
        if(returnAccess) viewTables.push("returns");
        if(destructionAccess) viewTables.push("destruction");
        if(siteAccess) viewTables.push("sites");
        if(sponsorAccess) viewTables.push("sponsor");
        if(studyAccess) viewTables.push("study");

        const body = {
            "sponsorid": sponsor.id,
            "email": email,
            "firstname": firstName,
            "lastname": lastName,
            "phonenumber": phoneNumber,
            "password": password,
            "confirmpassword": confirmPassword,
            "userdata": {
                "data": "data"
            },
            "sponsorroleneeded": sponsorRoleNeeded,
            "dashboard": sponsorRoleNeeded ? dashboardAccess : true,
            "viewtables": viewTables
        };

        addUser(body).then(_ => {
            fetchData();
            closeModal();
        });
    };

    function updateUser() {
        updateItem(
            'users', 
            ['email', 'firstname', 'lastname', 'phone'], 
            [email, firstName, lastName, phoneNumber], 
            { id: editUser.id }
        )
        .then(_ => {fetchData(); closeModal();});
    }

    return (
        <>
            {!showResetPassword ? (
                <div>
                    <IonGrid>
                        <IonRow>
                            <IonCol size="6">
                                <IonItem lines="none">
                                    <IonInput placeholder="First Name" value={firstName} onIonChange={e => setFirstName(e.detail.value)} />
                                </IonItem>
                            </IonCol>
                            <IonCol size="6">
                                <IonItem lines="none">
                                    <IonInput placeholder="Last Name" value={lastName} onIonChange={e => setLastName(e.detail.value)} />
                                </IonItem>
                            </IonCol>
                            <IonCol size="6">
                                <IonItem lines="none">
                                    <IonInput placeholder="Email" value={email} onIonChange={e => setEmail(e.detail.value)} />
                                </IonItem>
                            </IonCol>
                            <IonCol size="6">
                                <IonItem lines="none">
                                    <IonInput placeholder="Phone Number" value={phoneNumber} onIonChange={e => setPhoneNumber(e.detail.value)} />
                                </IonItem>
                            </IonCol>
                            {!editUser && <>
                                    <IonCol size="6">
                                        <IonItem lines="none">
                                            <IonInput type="password" placeholder="Password" value={password} onIonChange={e => setPassword(e.detail.value)} />
                                        </IonItem>
                                    </IonCol>
                                    <IonCol size="6">
                                        <IonItem lines="none">
                                            <IonInput type="password" placeholder="Confirm Password" value={confirmPassword} onIonChange={e => setConfirmPassword(e.detail.value)} />
                                        </IonItem>
                                    </IonCol>
                                    <IonCol size="12">
                                        <IonItem lines="none">
                                            Internal user
                                            <IonCheckbox checked={!sponsorRoleNeeded} value={!sponsorRoleNeeded} onIonChange={e => setSponsorRoleNeeded(!e.detail.checked)} slot="end"/>
                                        </IonItem>
                                    </IonCol>
                                    <IonCol size="12">
                                        <IonItem lines="none">
                                            External user
                                            <IonCheckbox checked={sponsorRoleNeeded} value={sponsorRoleNeeded} onIonChange={e => setSponsorRoleNeeded(e.detail.checked)} slot="end"/>
                                        </IonItem>
                                    </IonCol>
                                </>}
                            {sponsorRoleNeeded && !editUser && 
                                <IonCol size="12">
                                    <div style={{width: '100%', paddingLeft: 16, paddingRight: 16}}>
                                        <Select
                                            placeholder="Select Sponsor"
                                            labelField="name"
                                            searchBy="name"
                                            valueField="name"
                                            addPlaceholder=""
                                            searchable="true"
                                            options={sponsors}
                                            ref={selectSponsor}
                                            values={sponsor ? [sponsor] : []}
                                            onChange={(values) => {
                                                if(values.length > 0) {
                                                    setSponsor(values[0]);
                                                }
                                            }}
                                        />
                                    </div>
                                </IonCol>}
                            {sponsor && sponsorRoleNeeded && !editUser && <>
                            <IonCol>
                                <h1>System access for {sponsor.name}</h1>
                            </IonCol>
                            <IonCol size="12">
                                <IonItem lines="none">
                                    Dashboard access
                                    <IonCheckbox checked={dashboardAccess} value={dashboardAccess} onIonChange={e => setDashboardAccess(e.detail.checked)} slot="end"/>
                                </IonItem>
                            </IonCol>
                            <IonCol size="12">
                                <IonItem lines="none">
                                    Inbounds access
                                    <IonCheckbox checked={inboundAccess} value={inboundAccess} onIonChange={e => setInboundAccess(e.detail.checked)} slot="end"/>
                                </IonItem>
                            </IonCol>
                            <IonCol size="12">
                                <IonItem lines="none">
                                    Orders access
                                    <IonCheckbox checked={orderAccess} value={orderAccess} onIonChange={e => setOrderAccess(e.detail.checked)} slot="end"/>
                                </IonItem>
                            </IonCol>
                            <IonCol size="12">
                                <IonItem lines="none">
                                    Returns access
                                    <IonCheckbox checked={returnAccess} value={returnAccess} onIonChange={e => setReturnAccess(e.detail.checked)} slot="end"/>
                                </IonItem>
                            </IonCol>
                            <IonCol size="12">
                                <IonItem lines="none">
                                    Site access
                                    <IonCheckbox checked={siteAccess} value={siteAccess} onIonChange={e => setSiteAccess(e.detail.checked)} slot="end"/>
                                </IonItem>
                            </IonCol>
                            <IonCol size="12">
                                <IonItem lines="none">
                                    Study access
                                    <IonCheckbox checked={studyAccess} value={studyAccess} onIonChange={e => setStudyAccess(e.detail.checked)} slot="end"/>
                                </IonItem>
                            </IonCol>
                            </>}
                        </IonRow>
                        <IonRow>
                            {editUser && <IonCol>
                                <IonButton onClick={() => setShowResetPassword(true)}>
                                    Reset Password
                                    <IonIcon slot="start" icon={refreshOutline} />
                                </IonButton>
                            </IonCol>}
                            <IonCol>
                            </IonCol>
                            <IonCol>
                                {!editUser ? <IonButton color="success" expand="full" onClick={add}>
                                    <IonIcon icon={checkmarkOutline} slot="start" />
                                    Create
                                </IonButton> : 
                                <IonButton color="success" expand="full" onClick={updateUser}>
                                    <IonIcon icon={checkmarkOutline} slot="start" />
                                    Update
                                </IonButton>}
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </div>
            ) : <ResetPassword editUser={editUser} />}
        </>
    )
}
