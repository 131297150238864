// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./background-cube.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* ion-button {\r\n    --border-radius: 50% !important;\r\n} */\r\n\r\n.login {\r\n    height: 100vh; \r\n    display: flex; \r\n    align-items: center;\r\n    justify-content: center;\r\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\r\n    background-repeat: no-repeat;\r\n    background-position: right bottom;\r\n}\r\n\r\n.forgot-password-div {\r\n    width: 100%;\r\n    text-align: center;\r\n    margin-top: 1em;\r\n}\r\n\r\n.sign-up-div {\r\n    width: 100%;\r\n    text-align: center;\r\n    margin-top: 2em;\r\n}", "",{"version":3,"sources":["webpack://src/pages/Login/Login.css"],"names":[],"mappings":"AAAA;;GAEG;;AAEH;IACI,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,yDAA4C;IAC5C,4BAA4B;IAC5B,iCAAiC;AACrC;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,eAAe;AACnB","sourcesContent":["/* ion-button {\r\n    --border-radius: 50% !important;\r\n} */\r\n\r\n.login {\r\n    height: 100vh; \r\n    display: flex; \r\n    align-items: center;\r\n    justify-content: center;\r\n    background-image: url(./background-cube.png);\r\n    background-repeat: no-repeat;\r\n    background-position: right bottom;\r\n}\r\n\r\n.forgot-password-div {\r\n    width: 100%;\r\n    text-align: center;\r\n    margin-top: 1em;\r\n}\r\n\r\n.sign-up-div {\r\n    width: 100%;\r\n    text-align: center;\r\n    margin-top: 2em;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
