import React, { useState } from 'react';
import { IonGrid, IonRow, IonCol, IonInput, IonItem, IonButton, IonIcon } from '@ionic/react';
import { refreshOutline } from 'ionicons/icons';
import { changePassword } from '../apis/logictrials';

export default function ResetPassword({editUser, setShowModal}) {
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);

    function reset() {
        if(editUser && editUser.id) {
            changePassword(editUser.id, newPassword, confirmNewPassword).then(res => {
                if(res && res.statusCode === "OK") setIsSuccess(true);
            });
        }
    };

    return (
        <div>
            <IonGrid>
                {!isSuccess && <IonRow>
                    <IonCol size="12">
                        <h2>Reset Password {editUser && `for ${editUser.firstname} ${editUser.lastname}`}</h2>
                    </IonCol>
                    <IonCol size="12">
                        <IonItem lines="none">
                            <IonInput type="password" placeholder="New Password" value={newPassword} onIonChange={e => setNewPassword(e.detail.value)} />
                        </IonItem>
                    </IonCol>
                    <IonCol size="12">
                        <IonItem lines="none">
                            <IonInput type="password" placeholder="Confirm New Password" value={confirmNewPassword} onIonChange={e => setConfirmNewPassword(e.detail.value)} />
                        </IonItem>
                    </IonCol>
                    <IonCol size="12">
                        <IonItem lines="none">
                            <p>Resetting {editUser && `${editUser.firstname} ${editUser.lastname}'s`} password will send an email to {editUser && editUser.email} with their new password.</p>
                        </IonItem>
                    </IonCol>
                    <IonCol>
                        <IonButton color="success" expand="full" onClick={reset}>
                            <IonIcon icon={refreshOutline} slot="start" />
                            Reset
                        </IonButton>
                    </IonCol>
                </IonRow>}
                {isSuccess && 
                    <div className="mt-40">
                        <IonRow>
                            <IonCol size="12">
                                <IonItem lines="none">
                                    <p>Password for {editUser && `${editUser.firstname} ${editUser.lastname}`} reset successfully.</p>
                                </IonItem>
                            </IonCol>
                            <IonCol>
                                <IonButton color="success" expand="full" onClick={() => setShowModal(false)}>
                                    Okay
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </div>
                }
            </IonGrid>
        </div>
    )
}
