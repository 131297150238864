import React, { useState, useEffect } from 'react';
import { IonGrid, IonRow, IonCol, IonCheckbox, IonInput, IonItem, IonButton, IonModal } from '@ionic/react';
import DayPicker from 'react-day-picker';
import { format } from 'date-fns';
import { updateItem } from '../../apis/logictrials';
import { isValidDate } from '../../utils';

export default function ConfirmTt4({ updateReturn, setShowModal }) {
    const [tt4Required, setTt4Required] = useState(false);
    const [tt4ReturnRequired, setTt4ReturnRequired] = useState(false);
    const [excursion, setExcursion] = useState(false);
    const [tt4Date, setTt4Date] = useState();
    const [showTt4Date, setShowTt4Date] = useState({ showPopover: false, event: undefined });
    const [tt4ReturnData, setTt4ReturnData] = useState(null);

    useEffect(() => {
        if (updateReturn && updateReturn.tt4Excursion === "yes") setExcursion(true);

        if (updateReturn && updateReturn.TT4 && typeof updateReturn.TT4.label === 'string' && updateReturn.TT4.label.includes('(return)') && isValidDate(new Date(updateReturn.TT4.label.replace(' (return)', '')))) {
            setTt4Date(new Date(updateReturn.TT4.label));
            setTt4Required(true);
            setTt4ReturnRequired(true);
        };

        if (updateReturn && updateReturn.TT4 && typeof updateReturn.TT4.label === 'string' && updateReturn.TT4.label === "required, no return") {
            setTt4Required(true);
        };

        if (updateReturn && updateReturn.aramexCollection && (typeof updateReturn.aramexCollection === 'object') && updateReturn.aramexCollection.request && updateReturn.aramexCollection.status) {
            setTt4ReturnData({ ...updateReturn.aramexCollection, waybill: updateReturn.aramexReturn && updateReturn.aramexReturn.received });
        } else if (updateReturn && updateReturn.aramexReturn && (typeof updateReturn.aramexReturn === 'object') && updateReturn.aramexReturn.received && !updateReturn.aramexCollection) {
            setTt4ReturnData({ waybill: updateReturn.aramexReturn && updateReturn.aramexReturn.received });
        };

        return () => {
            setTt4ReturnData(null);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const submit = () => {
        let updated = updateReturn;
        updated.TT4 = resolveTt4();
        updated.tt4Excursion = excursion ? "yes" : "N/A";
        updateItem('orders', ['data'], [updated], { id: updateReturn.id }).then(res => {
            setShowModal(false);
        })
    };

    function resolveTt4() {
        if (!tt4Required) return 'not required';

        if (tt4Required && !tt4Date && !tt4ReturnRequired) return {
            "label": 'required, no return',
            "btnIcon": "barChartOutline",
            "tooltip": "Confirm TT4",
            "btnColor": "warning"
        };

        if (tt4Required && tt4Date) return {
            "label": !tt4ReturnRequired ? 'no return' : format(new Date(tt4Date), 'yyyy-MM-d') + ' (return)',
            "btnIcon": "barChartOutline",
            "tooltip": "Confirm TT4",
            "btnColor": "warning"
        };
    }

    return (
        <div>
            <IonGrid>
                <IonRow style={{ marginTop: '3em' }}>
                    <IonCol size-md="12">
                        <IonItem lines="none">
                            Temptale required:
                            <IonCheckbox checked={tt4Required} value={tt4Required} onIonChange={e => setTt4Required(e.detail.checked)} slot="end" />
                        </IonItem>
                    </IonCol>
                    {tt4Required && <IonCol size-md="12">
                        <IonItem lines="none">
                            Temptale return required:
                            <IonCheckbox checked={tt4ReturnRequired} value={tt4ReturnRequired} onIonChange={e => setTt4ReturnRequired(e.detail.checked)} slot="end" />
                        </IonItem>
                    </IonCol>}
                    {tt4ReturnRequired &&
                        <IonCol size-md="12">
                            <IonItem lines="none">
                                <IonInput onClick={(e) => setShowTt4Date({ showPopover: true, event: e })} placeholder="Temptale Return Date" value={tt4Date ? format(new Date(tt4Date), 'MM d y') : ''} />
                            </IonItem>
                        </IonCol>
                    }
                    {tt4Required && <IonCol size-md="12">
                        <IonItem lines="none" style={{ color: 'var(--ion-color-danger)', '--color-hover': 'var(--ion-color-danger)' }}>
                            Excursion:
                            <IonCheckbox color="danger" checked={excursion} value={excursion} onIonChange={e => setExcursion(e.detail.checked)} slot="end" />
                        </IonItem>
                    </IonCol>}
                    {tt4ReturnData && <IonCol size-md="12">
                        <div style={{ border: '1px solid lightgray', padding: '0.25rem', borderRadius: 10 }}>
                            <IonItem lines="none">
                                <h5>Return TT4 Information</h5>
                            </IonItem>
                            {tt4ReturnData && tt4ReturnData.request && (tt4ReturnData.request !== 'null') && <IonItem lines="none">
                                <p><span style={{ fontWeight: 'bold', marginRight: '1em' }}>Aramex Reference:</span>{tt4ReturnData && tt4ReturnData.request}</p>
                            </IonItem>}
                            {tt4ReturnData && tt4ReturnData.status && <IonItem lines="none">
                                <p><span style={{ fontWeight: 'bold', marginRight: '1em' }}>API Request Status:</span><span style={{ textTransform: 'lowercase', color: (tt4ReturnData && tt4ReturnData.status && (tt4ReturnData.status === "TRANSACTION SUCCESSFUL")) ? 'var(--ion-color-success)' : 'var(--ion-color-danger)' }}>{tt4ReturnData && tt4ReturnData.status}</span></p>
                            </IonItem>}
                            {tt4ReturnData && tt4ReturnData.waybill && <IonItem lines="none">
                                <a href={tt4ReturnData && tt4ReturnData.waybill} target="_blank" rel="noopener noreferrer">
                                    <IonButton expand="full" color="success">
                                        View Waybill
                                    </IonButton>
                                </a>
                            </IonItem>}
                        </div>
                    </IonCol>}
                </IonRow>
                <IonRow>
                    <IonCol style={{ textAlign: 'right', marginTop: '3em' }} size-md="12">
                        <IonButton expand="full" color="warning" onClick={submit}>
                            Update
                        </IonButton>
                    </IonCol>
                </IonRow>
            </IonGrid>
            <IonModal
                cssClass="day-modal-picker"
                isOpen={showTt4Date.showPopover}
                event={showTt4Date.event}
                onDidDismiss={() => setShowTt4Date({ showPopover: false, event: undefined })}
            >
                <DayPicker canChangeMonth={true} onDayClick={(day) => { setTt4Date(day.toString()); setShowTt4Date({ showPopover: false, event: undefined }); }} />
                <IonButton onClick={() => setShowTt4Date({ showPopover: false, event: undefined })}>Close</IonButton>
            </IonModal>
        </div>
    )
}
