import React, { useState } from 'react';
import { IonGrid, IonRow, IonCol, IonInput, IonItem, IonButton } from '@ionic/react';
import { forgotPassword } from '../apis/logictrials';

export default function RestePassword() {
    const [email, setEmail] = useState('');

    function submit() {
        if(email) {
            forgotPassword(email);
        }
    };

    return (
        <div>
            <IonGrid>
                <IonRow>
                    <IonCol size="12">
                        <h2>Forgot Password</h2>
                    </IonCol>
                    <IonCol size="12">
                        <IonItem lines="none">
                            <IonInput type="text" placeholder="Your email" value={email} onIonChange={e => setEmail(e.detail.value)} />
                        </IonItem>
                    </IonCol>
                    <IonCol size="12">
                        <IonItem lines="none">
                            <p>Get a link to reset your password sent to you.</p>
                        </IonItem>
                    </IonCol>
                    <IonCol>
                        <IonButton color="success" expand="full" onClick={submit}>
                            {/* <IonIcon icon={refreshOutline} slot="start" /> */}
                            Submit
                        </IonButton>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </div>
    )
}
